import Styles from './Search.module.scss';
import { BsSearch } from 'react-icons/bs';

const Search = ({ onChange, placeholder }) => {
  return (
    <div>
      <form
        style={{ maxWidth: '95%', margin: 'auto' }}
        className={`form-group ${Styles.hasSearch}`}
      >
        <BsSearch className={`${Styles.formControlSearchIcon}`} />
        <input
          type="text"
          className={`form-control form-control-sm shadow-none ${Styles.SearchBox}`}
          placeholder={placeholder}
          aria-label="Search"
          onChange={onChange}
        />
      </form>
    </div>
  );
};

export default Search;
