const config = {
  baseURL: "https://plus.lifebank.ke",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const token = localStorage.getItem("jwt-token");
if (token) {
  config.headers.authorization = `Bearer ${token}`;
}

export default config;
