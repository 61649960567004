import axiosClient from "../../../service/axios";
import {
  BOAT_URL,
  CALL_URL,
  COMPLETED_URL,
  LABX_URL,
  ONGOING_URL,
  OVERDUE_URL,
  USSD_URL,
  NEW_ORDER_URL,
  HOSPITAL_URL,
  DISPATCH_URL,
  LAB_URL,
  CLOSE_ORDER_URL,
  RETURN_ORDER_URL,
  COMPLAIN_ORDER_URL,
  PROCESS_ORDER_URL,
  OXYGEN_URL,
  TRACK_ORDER_URL,
  ORDER_PRICE_URL,
  RIDER_URL,
  STOCKBANK_URL,
  NOTIFICATION_URL,
  VIEW_NOTIFICATION,
  UPDATE_ORDER_URL,
} from "./orders.endpoints";

//@get labX orders
const fetchLabXOrders = async () => {
  try {
    return await axiosClient.get(LABX_URL);
  } catch (error) {
    return error;
  }
};

//@get ussd orders
const fetchUssdOrders = async () => {
  try {
    return await axiosClient.get(USSD_URL);
  } catch (error) {
    return error;
  }
};

//@get call orders
const fetchCallOrders = async () => {
  try {
    return await axiosClient.get(CALL_URL);
  } catch (error) {
    return error;
  }
};

//@get boat orders
const fetchBoatOrders = async () => {
  try {
    return await axiosClient.get(BOAT_URL);
  } catch (error) {
    return error;
  }
};

//@get ongoing orders
const fetchOngoingOrders = async () => {
  try {
    return await axiosClient.get(ONGOING_URL);
  } catch (error) {
    return error;
  }
};

//@get ongoing orders
const fetchOverdueOrders = async () => {
  try {
    return await axiosClient.get(OVERDUE_URL);
  } catch (error) {
    return error;
  }
};

//@get completed orders
const fetchCompletedOrders = async () => {
  try {
    return await axiosClient.get(COMPLETED_URL);
  } catch (error) {
    return error;
  }
};

/*
@create New Order

*/
const createNewOrder = async (details) => {
  try {
    return await axiosClient.post(NEW_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@fetch hospital detail

*/
const fetchHospital = async () => {
  try {
    return await axiosClient.get(HOSPITAL_URL);
  } catch (error) {
    return error;
  }
};

/*
@fetch dispatch list

*/
const fetchDispatch = async () => {
  try {
    return await axiosClient.get(DISPATCH_URL);
  } catch (error) {
    return error;
  }
};

/*
@fetch lab list

*/
const fetchLabs = async () => {
  try {
    return await axiosClient.get(LAB_URL);
  } catch (error) {
    return error;
  }
};

/*
@fetch oxygen list

*/
const fetchOxygen = async () => {
  try {
    return await axiosClient.get(OXYGEN_URL);
  } catch (error) {
    return error;
  }
};

/*
@fetch rider list

*/
const fetchRiders = async () => {
  try {
    return await axiosClient.get(RIDER_URL);
  } catch (error) {
    return error;
  }
};

/*
@close order

*/
const closeOrder = async (details) => {
  try {
    return await axiosClient.post(CLOSE_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@Return order

*/
const returnOrder = async (details) => {
  try {
    return await axiosClient.post(RETURN_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@Complain order

*/
const complainOrder = async (details) => {
  try {
    return await axiosClient.post(COMPLAIN_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@Complain order

*/
const processOrder = async (details) => {
  try {
    return await axiosClient.post(PROCESS_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@track order

*/
const trackOrder = async (details, id) => {
  try {
    return await axiosClient.post(TRACK_ORDER_URL + id, details);
  } catch (error) {
    return error;
  }
};

/*
@order price

*/
const orderPrice = async (details) => {
  try {
    return await axiosClient.post(ORDER_PRICE_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@fetch rider list

*/
const fetchStockbank = async (id) => {
  try {
    return await axiosClient.get(STOCKBANK_URL + id);
  } catch (error) {
    return error;
  }
};

/*
@fetch Notification

*/
const fetchNotifications = async () => {
  try {
    return await axiosClient.get(NOTIFICATION_URL);
  } catch (error) {
    return error;
  }
};

/*
@view Notification

*/
const viewNotification = async (id, channel) => {
  try {
    return await axiosClient.get(`${VIEW_NOTIFICATION}/${id}/${channel}`);
  } catch (error) {
    return error;
  }
};

/* 
@Update Order details

*/

const updateOrder = async (details) => {
  try {
    return await axiosClient.post(UPDATE_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};
const OrderService = {
  fetchLabXOrders,
  fetchOngoingOrders,
  fetchOverdueOrders,
  fetchCompletedOrders,
  fetchBoatOrders,
  fetchCallOrders,
  fetchUssdOrders,
  fetchHospital,
  fetchDispatch,
  fetchLabs,
  createNewOrder,
  closeOrder,
  returnOrder,
  complainOrder,
  processOrder,
  fetchOxygen,
  trackOrder,
  orderPrice,
  fetchRiders,
  fetchStockbank,
  fetchNotifications,
  viewNotification,
  updateOrder,
};

export default OrderService;
